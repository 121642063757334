import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/service/authentication.service';
import { AppMainComponent } from './app.main.component';

@Component({
    selector: 'app-menu',
    template: `
        <div class="layout-menu-container">
            <ul class="layout-menu" role="menu" (keydown)="onKeydown($event)">
                <li
                    app-menu
                    class="layout-menuitem-category"
                    *ngFor="let item of model; let i = index"
                    [item]="item"
                    [index]="i"
                    [root]="true"
                    role="none"
                >
                    <div
                        class="layout-menuitem-root-text"
                        [attr.aria-label]="item.label"
                    >
                        {{ item.label }}
                    </div>
                    <ul role="menu">
                        <li
                            app-menuitem
                            *ngFor="let child of item.items"
                            [parent]="item.label"
                            [item]="child"
                            [index]="i"
                            role="none"
                        ></li>
                    </ul>
                </li>
            </ul>
        </div>
    `,
})
export class AppMenuComponent implements OnInit {
    model: any[];

    constructor(
        public appMain: AppMainComponent,
        public router: Router,
        public auth: AuthenticationService
    ) {}

    ngOnInit() {
        // if(this.router.url.includes('showcase'))
        // {
        this.model = [
            {
                items: [
                    {
                        label: 'Workbench',
                        icon: 'fas fa-toolbox',
                        routerLink: ['/admin/workbench'],
                        subMenu: [
                            {
                                label: 'Settings',
                                styleClass: '',
                                items: [
                                    {
                                        label: 'Transaction Auto Numbering',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workbench/settings/transaction-auto-numbering/list',
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                items: [
                    {
                        label: 'Organization',
                        icon: 'fas fa-sitemap',
                        routerLink: ['/admin/organization'],
                        subMenu: [
                            {
                                label: 'Settings',
                                styleClass: '',
                                items: [
                                    {
                                        label: 'Job Attribute',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/organization/settings/job-attribute/list',
                                        ],
                                    },
                                    {
                                        label: 'Cost Center',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/organization/settings/cost-center/list',
                                        ],
                                    },
                                    {
                                        label: 'MPP',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/organization/settings/mpp/list',
                                        ],
                                    },
                                ],
                            },
                            {
                                label: 'Activities',
                                styleClass: '',
                                items: [
                                    {
                                        label: 'Company',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/organization/activities/company/list',
                                        ],
                                    },
                                    {
                                        label: 'Organization Master',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/organization/activities/organization-master-level/list',
                                        ],
                                    },
                                    {
                                        label: 'Job Design',
                                        styleClass: '',
                                        items: [
                                            {
                                                label: 'Job Title',
                                                styleClass: '',
                                                routerLink: [
                                                    '/admin/organization/activities/job-title/list',
                                                ],
                                            },
                                            {
                                                label: 'Job Level',
                                                styleClass: '',
                                                routerLink: [
                                                    '/admin/organization/activities/job-level/list',
                                                ],
                                            },
                                            {
                                                label: 'Employee Type',
                                                styleClass: '',
                                                routerLink: [
                                                    '/admin/organization/activities/employee-type/list',
                                                ],
                                            },
                                            {
                                                label: 'Job Position',
                                                styleClass: '',
                                                routerLink: [
                                                    '/admin/organization/activities/job-position/list',
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        label: 'Cost Center',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/organization/activities/cost-center/list',
                                        ],
                                    },
                                    {
                                        label: 'MPP',
                                        styleClass: '',
                                        items: [
                                            {
                                                label: 'MPP Period',
                                                styleClass: '',
                                                routerLink: [
                                                    '/admin/organization/activities/mpp-period/list',
                                                ],
                                            },
                                            {
                                                label: 'MPP Request',
                                                styleClass: '',
                                                routerLink: [
                                                    '/admin/organization/activities/mpp-request/list',
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        label: 'Job Attribute Requests',
                                        styleClass: '',
                                        items: [
                                            {
                                                label: 'Organization Request',
                                                styleClass: '',
                                                routerLink: [
                                                    '/admin/organization/activities/organization-request/list',
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                items: [
                    {
                        label: 'Workforce',
                        icon: 'fas fa-users',
                        routerLink: ['/admin/workforce'],
                        subMenu: [
                            {
                                label: 'Settings',
                                styleClass: '',
                                items: [
                                    {
                                        label: 'Movement Master',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/settings/movement-type/list',
                                        ],
                                    },
                                    {
                                        label: 'Vendor Master',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/settings/vendor-master/list',
                                        ],
                                    },

                                    {
                                        label: 'Person',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/workforce/settings/person/relationship/list',
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                items: [
                    {
                        label: 'Time & Attendance',
                        icon: 'fas fa-clock',
                        routerLink: ['/admin/time'],
                        subMenu: [
                            {
                                label: 'Settings',
                                styleClass: '',
                                items: [
                                    {
                                        label: 'Color',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/settings/color-master/list',
                                        ],
                                    },
                                    {
                                        label: 'Time Attendance',
                                        styleClass: '',
                                        routerLink: [
                                            '/admin/time/settings/attendance-code/list',
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                items: [
                    {
                        label: 'Reimbursement',
                        icon: 'fas fa-hand-holding-usd',
                        routerLink: ['/admin/reimbursement'],
                    },
                ],
            },
            {
                items: [
                    {
                        label: 'Payroll',
                        icon: 'fas fa-money-bill',
                        routerLink: ['/admin/payroll'],
                    },
                ],
            },
            {
                items: [
                    {
                        label: 'Recruitment',
                        icon: 'fas fa-user-plus',
                        routerLink: ['/showcase'],
                    },
                ],
            },
            {
                items: [
                    {
                        label: 'Competency',
                        icon: 'fas fa-chart-simple',
                        routerLink: ['/showcase'],
                    },
                ],
            },
            {
                items: [
                    {
                        label: 'Performance',
                        icon: 'fas fa-chart-line',
                        routerLink: ['/showcase'],
                    },
                ],
            },
            {
                items: [
                    {
                        label: 'Loan',
                        icon: 'fas fa-credit-card',
                        routerLink: ['/showcase'],
                    },
                ],
            },
            {
                items: [
                    {
                        label: 'Career Path',
                        icon: 'fas fa-map-signs',
                        routerLink: ['/showcase'],
                    },
                ],
            },
            {
                items: [
                    {
                        label: 'Learning Management System',
                        icon: 'fas fa-graduation-cap',
                        routerLink: ['/showcase'],
                    },
                ],
            },
            {
                items: [
                    {
                        label: 'Log History',
                        icon: 'fa-solid fa-clock-rotate-left',
                        routerLink: ['/util/log-history/admin'],
                    },
                ],
            },
        ];
        // }else{
        //     this.model=[{
        //         label:'Menu',
        //         items:this.auth.getSession().user.tenant.tenantModules
        //     }]
        // }
    }

    onKeydown(event: KeyboardEvent) {
        const nodeElement = <HTMLDivElement>event.target;
        if (event.code === 'Enter' || event.code === 'Space') {
            nodeElement.click();
            event.preventDefault();
        }
    }
}
