import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageBoxService } from "@core/service/message-box.service";
import { lastValueFrom } from "rxjs";
import { AuthForgotPasswordSentServiceService } from "./auth-forgot-password-sent.service.service";

@Component({
    selector: "app-auth-forgot-password-sent",
    templateUrl: "./auth-forgot-password-sent.component.html",
    styleUrls: ["./auth-forgot-password-sent.component.scss"],
})
export class AuthForgotPasswordSentComponent implements OnInit {
    email: string;

    constructor(
        private msg: MessageBoxService,
        private route: ActivatedRoute,
        private router: Router,
        private srv: AuthForgotPasswordSentServiceService
    ) {}

    ngOnInit(): void {
        this.email = this.route.snapshot.queryParamMap.get("email");
    }

    resend() {
        const form = {
            email: this.email,
        };

        lastValueFrom(this.srv.storeForgotPassword(form))
            .then((res) => {
                let resp = JSON.parse(res);
                this.msg.showSuccess(resp.data);
            })
            .catch((err) => {
                let resp = JSON.parse(err);
                this.msg.showError(resp.errors.reason);
            });
    }
}
