<div
    style="background-color: #f9f9f9"
    style="min-height: 100vh; display: flex; flex-direction: column"
>
    <topbar></topbar>
    <div class="layout-main-container">
        <flash-message-error></flash-message-error>
        <flash-message-success></flash-message-success>
        <router-outlet *ngIf="!config.loading"></router-outlet>
    </div>
</div>
<footer style="margin-top: auto"></footer>
