import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { BirthdayDashboardComponent } from './component/birthday-dashboard/birthday-dashboard.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FormsModule } from '@angular/forms';
import { SharedComponentModule } from '../shared-component/shared-component.module';
import { LeaveRequestDashboardComponent } from './component/leave-request-dashboard/leave-request-dashboard.component';
import { AttendanceDashboardComponent } from './component/attendance-dashboard/attendance-dashboard.component';
import { TodayLeaveDashboardComponent } from './component/today-leave-dashboard/today-leave-dashboard.component';
import { NewEmployeeDashboardComponent } from './component/new-employee-dashboard/new-employee-dashboard.component';
import { LateLoanPaymentDashboardComponent } from './component/late-loan-payment-dashboard/late-loan-payment-dashboard.component';
import { EmployeeInfoDashboardComponent } from './component/employee-info-dashboard/employee-info-dashboard.component';
import { LoanInfoDashboardComponent } from './component/loan-info-dashboard/loan-info-dashboard.component';
import { ReimburseInfoDashboardComponent } from './component/reimburse-info-dashboard/reimburse-info-dashboard.component';
import { RequestInfoDashboardComponent } from './component/request-info-dashboard/request-info-dashboard.component';
import { PayrollInfoDashboardComponent } from './component/payroll-info-dashboard/payroll-info-dashboard.component';
import { ConfirmationDashboardComponent } from './component/confirmation-dashboard/confirmation-dashboard.component';
import { TabViewModule } from 'primeng/tabview';
import { PayrollMonthlyDashboardComponent } from './component/payroll-monthly-dashboard/payroll-monthly-dashboard.component';

@NgModule({
    declarations: [
        DashboardComponent,
        BirthdayDashboardComponent,
        LeaveRequestDashboardComponent,
        AttendanceDashboardComponent,
        TodayLeaveDashboardComponent,
        NewEmployeeDashboardComponent,
        LateLoanPaymentDashboardComponent,
        EmployeeInfoDashboardComponent,
        LoanInfoDashboardComponent,
        ReimburseInfoDashboardComponent,
        RequestInfoDashboardComponent,
        PayrollInfoDashboardComponent,
        ConfirmationDashboardComponent,
        PayrollMonthlyDashboardComponent,
    ],
    imports: [
        CommonModule,
        NgApexchartsModule,
        FormsModule,
        SharedComponentModule,
        TabViewModule,
    ],
})
export class DashboardModule {}
