<flash-message-error></flash-message-error>
<flash-message-success></flash-message-success>
<div class="hide-mobile">
    <div class="flex">
        <div class="left">
            <banner-section></banner-section>
        </div>
        <div class="right flex justify-content-center">
            <form class="form-desktop" [formGroup]="form" (ngSubmit)="submit()">
                <div class="mb-3 title"><b>Forgot Password</b></div>
                <div class="mb-3">
                    Enter your email address and we’ll send an email with a link
                    to reset your password
                </div>
                <div class="flex flex-column mb-4">
                    <label class="label">Email Address</label>
                    <input
                        pInputText
                        placeholder="Input email address"
                        formControlName="email"
                    />
                    <div
                        *ngIf="f['email'].touched && f['email'].invalid"
                        class="validation-message"
                    >
                        <div
                            *ngIf="
                                f['email'].errors &&
                                f['email'].errors['required']
                            "
                        >
                            Email is required.
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="p-fluid">
                        <button
                            pButton
                            pRipple
                            label="Send Password Reset"
                            class="btn"
                            [disabled]="!form.valid"
                        ></button>
                    </div>
                </div>
                <div class="text-center">
                    <a href="/login">Back To Sign In</a>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="hide-desktop">
    <banner-section-responsive></banner-section-responsive>
    <div class="flex justify-content-center position-up relative">
        <div class="justify-content-center">
            <form
                class="container-mobile form-mobile"
                [formGroup]="form"
                (ngSubmit)="submit()"
            >
                <div class="mb-3 title"><b>Forgot Password</b></div>
                <div class="mb-3">
                    Enter your email address and we’ll send an email with a link
                    to reset your password
                </div>
                <div class="flex flex-column mb-4">
                    <label class="label">Email Address</label>
                    <input
                        pInputText
                        placeholder="Input email address"
                        formControlName="email"
                    />
                    <div
                        *ngIf="f['email'].touched && f['email'].invalid"
                        class="validation-message"
                    >
                        <div
                            *ngIf="
                                f['email'].errors &&
                                f['email'].errors['required']
                            "
                        >
                            Email is required.
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="p-fluid">
                        <button
                            pButton
                            pRipple
                            label="Send Password Reset"
                            class="btn"
                            [disabled]="!form.valid"
                        ></button>
                    </div>
                </div>
                <div class="text-center">
                    <a href="/login">Back To Sign In</a>
                </div>
            </form>
        </div>
    </div>
</div>
