import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { lastValueFrom } from "rxjs";
import { MessageBoxService } from "@core/service/message-box.service";
import { AuthLoginServiceService } from "./auth-login.service.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "app-auth-login",
    templateUrl: "./auth-login.component.html",
    styleUrls: ["./auth-login.component.scss"],
})
export class AuthLoginComponent {
    form!: FormGroup;
    public isLoading: boolean = false;

    constructor(
        private srv: AuthLoginServiceService,
        private msg: MessageBoxService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    username: string;
    password: string;
    checked: boolean = false;
    token;

    ngOnInit(): void {
        this.form = new FormGroup({
            username: new FormControl("", [Validators.required]),
            password: new FormControl("", [Validators.required]),
        });

        this.route.url.subscribe((url) =>
            console.log("Current path:", url.join("/"))
        );
        this.route.queryParams.subscribe((params) => {
            this.token = params["token"];
        });
    }

    get f() {
        return this.form.controls;
    }

    submit() {
        this.isLoading = true;
        this.form.value.username = this.username;
        this.form.value.password = this.password;
        lastValueFrom(this.srv.storeLogin(this.form.value))
            .then((res) => {

                if(this.token){
                    const tokenObj = { token: this.token };
                    lastValueFrom(this.srv.verif(tokenObj))
                }
                

                let resp = JSON.parse(res);
                localStorage.setItem("job-portal", JSON.stringify(resp.data));
                this.msg.showSuccess(resp.status, null, false);
                this.isLoading = false;
                this.router.navigateByUrl("/");
            })
            .catch((err) => {
                let resp = JSON.parse(err);
                this.msg.showError(resp.errors.reason);
                this.isLoading = false;
            });
    }
}
