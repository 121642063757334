import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BannerSectionResponsiveComponent } from "./banner-section-responsive.component";

@NgModule({
    declarations: [BannerSectionResponsiveComponent],
    imports: [CommonModule],
    exports: [BannerSectionResponsiveComponent],
})
export class BannerSectionResponsiveModule {}
