import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AuthGuard } from "./core/auth.guard";
import { DashboardComponent } from "./core/dashboard/dashboard.component";
import { AppMainComponent } from "./layout/component/app.main.component";
import { PageForbiddenComponent } from "./layout/page-forbidden/page-forbidden.component";
import { PageNotFoundComponent } from "./layout/page-not-found/page-not-found.component";
import { RedirectComponent } from "./layout/redirect/redirect.component";

import { MainComponent } from "./layout/main/main.component";
import { AuthForgotPasswordSentComponent } from "./pages/auth-forgot-password-sent/auth-forgot-password-sent.component";
import { AuthForgotPasswordComponent } from "./pages/auth-forgot-password/auth-forgot-password.component";
import { AuthLoginComponent } from "./pages/auth-login/auth-login.component";
import { AuthRegisterComponent } from "./pages/auth-register/auth-register.component";
import { AuthResetPasswordComponent } from "./pages/auth-reset-password/auth-reset-password.component";

@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: "dashboard",
                    component: AppMainComponent,
                    children: [{ path: "", component: DashboardComponent }],
                    canActivate: [AuthGuard],
                },
                {
                    path: "login",
                    component: AuthLoginComponent,
                },
                {
                    path: "login/verification",
                    component: AuthLoginComponent,
                },
                {
                    path: "register",
                    component: AuthRegisterComponent,
                },
                {
                    path: "forgot-password",
                    component: AuthForgotPasswordComponent,
                },
                {
                    path: "forgot-password-sent",
                    component: AuthForgotPasswordSentComponent,
                },
                {
                    path: "reset-password",
                    component: AuthResetPasswordComponent,
                },
                {
                    path: "profile",
                    component: MainComponent,
                    loadChildren: () =>
                        import("./pages/profile/profile.module").then(
                            (m) => m.ProfileModule
                        ),
                },
                {
                    path: "find-jobs",
                    component: MainComponent,
                    loadChildren: () =>
                        import("./pages/find-jobs/find-jobs.module").then(
                            (m) => m.FindJobsModule
                        ),
                },
                {
                    path: "find-jobs/:id",
                    component: MainComponent,
                    loadChildren: () =>
                        import("./pages/find-job/find-job.module").then(
                            (m) => m.FindJobModule
                        ),
                },
                {
                    path: "find-jobs/:id/detail",
                    component: MainComponent,
                    loadChildren: () =>
                        import(
                            "./pages/find-job-detail/find-job-detail.module"
                        ).then((m) => m.FindJobDetailModule),
                },
                {
                    path: "application",
                    component: MainComponent,
                    loadChildren: () =>
                        import("./pages/application/application.module").then(
                            (m) => m.ApplicationModule
                        ),
                },
                {
                    path: "selection-process/:id",
                    component: MainComponent,
                    loadChildren: () =>
                        import(
                            "./pages/selection-process/selection-process.module"
                        ).then((m) => m.SelectionProcessModule),
                },
                {
                    path: "selection-process/detail/:id",
                    component: MainComponent,
                    loadChildren: () =>
                        import(
                            "./pages/selection-process-detail/selection-process-detail.module"
                        ).then((m) => m.SelectionProcessDetailModule),
                },
                {
                    path: "saved-jobs",
                    component: MainComponent,
                    loadChildren: () =>
                        import("./pages/saved-jobs/saved-jobs.module").then(
                            (m) => m.SavedJobsModule
                        ),
                },
                {
                    path: "test/:jobVacancyid/:selectionProcessId/:qnaId",
                    component: MainComponent,
                    loadChildren: () =>
                        import("./pages/psycho-test/psycho-test.module").then(
                            (m) => m.PsychoTestModule
                        )
                },

                {
                    path: "redirect",
                    component: RedirectComponent,
                },
                {
                    path: "404",
                    component: PageNotFoundComponent,
                },
                {
                    path: "403",
                    component: PageForbiddenComponent,
                },
                {
                    path: "util",
                    component: AppMainComponent,
                    loadChildren: () =>
                        import("./util/util.module").then((m) => m.UtilModule),
                    // canActivate: [AuthGuard],
                },
                {
                    path: "showcase",
                    component: AppMainComponent,
                    loadChildren: () =>
                        import("./showcase/showcase.module").then(
                            (m) => m.ShowcaseModule
                        ),
                    // canActivate: [AuthGuard],
                },
                {
                    path: "",
                    pathMatch: "prefix",
                    redirectTo: "find-jobs",
                },
                { path: "**", redirectTo: "404" },
            ],
            {
                scrollPositionRestoration: "enabled",
                canceledNavigationResolution: "computed",
            }
        ),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
