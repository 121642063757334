import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthRegisterServiceService } from "./auth-register.service.service";
import { lastValueFrom } from "rxjs";
import { MessageBoxService } from "@core/service/message-box.service";

@Component({
    selector: "app-auth-register",
    templateUrl: "./auth-register.component.html",
    styleUrls: ["./auth-register.component.scss"],
})
export class AuthRegisterComponent {
    form!: FormGroup;

    constructor(
        private srv: AuthRegisterServiceService,
        private msg: MessageBoxService
    ) {}

    min8Char: boolean = false;
    lettNNum: boolean = false;
    capitalSmallLetters: boolean = false;
    checked: boolean = false;
    email: string;
    password: string;

    ngOnInit(): void {
        this.form = new FormGroup({
            username: new FormControl("", [Validators.required]),
            email: new FormControl("", [Validators.required]),
            password: new FormControl("", [Validators.required]),
            password_confirmation: new FormControl("", [Validators.required]),
            is_active: new FormControl(true),
        });
    }

    get f() {
        return this.form.controls;
    }

    // onChange(event: any) {
    //     if (event.target.value.length >= 8) {
    //         this.min8Char = true;
    //     } else {
    //         this.min8Char = false;
    //     }

    //     if (this.containsOnlyLettersAndNumbers(event.target.value)) {
    //         this.lettNNum = true;
    //     } else {
    //         this.lettNNum = false;
    //     }
    // }

    // containsOnlyLettersAndNumbers(str: any) {
    //     const pattern = /^[a-zA-Z0-9]+$/;
    //     return pattern.test(str);
    // }

    onChange(event: any) {
        const password = event.target.value;
    
        if (password.length >= 8) {
            this.min8Char = true;
        } else {
            this.min8Char = false;
        }
    
        if (this.containsOnlyLettersAndNumbers(password)) {
            this.lettNNum = true;
        } else {
            this.lettNNum = false;
        }
    
        if (this.containsCapitalAndSmallLetters(password)) {
            this.capitalSmallLetters = true;
        } else {
            this.capitalSmallLetters = false;
        }
    }
    
    containsCapitalAndSmallLetters(password: string) {
        return /[a-z]/.test(password) && /[A-Z]/.test(password);
    }

    containsOnlyLettersAndNumbers(str: string) {
        const letterPattern = /[a-zA-Z]/;
        const numberPattern = /[0-9]/;
        return letterPattern.test(str) && numberPattern.test(str);
    }
    

    submit() {
        this.form.value.email = this.email;
        this.form.value.password = this.password;
        if (
            this.form.value.password === this.form.value.password_confirmation
        ) {
            if (this.min8Char && this.lettNNum) {
                lastValueFrom(this.srv.storeSignup(this.form.value))
                    .then((res) => {
                        let resp = JSON.parse(res);
                        this.msg.showSuccess(resp.data, null, false);
                        window.location.href = "/login";
                    })
                    .catch((err) => {
                        let resp = JSON.parse(err);
                        this.msg.showError(resp.errors.reason);
                    });
            } else {
                this.msg.showError("password does not match specifications");
            }
        } else {
            this.msg.showError("password does not match");
        }
    }
}
