import { Injectable } from "@angular/core";
import { ApiService } from "@core/service/api.service";

@Injectable({
    providedIn: "root",
})
export class AuthForgotPasswordSentServiceService {
    constructor(private api: ApiService) {}

    storeForgotPassword(body) {
        return this.api.postLogin("users/send-reset-password-link", body);
    }
}
