import { Injectable } from "@angular/core";
import { ApiService } from "@core/service/api.service";

@Injectable({
    providedIn: "root",
})
export class AuthLoginServiceService {
    constructor(private api: ApiService) {}

    storeLogin(body) {
        return this.api.postLogin("auth/login", body);
    }

    verif(body) {
        return this.api.put("users/verification", body);
    }
}
