import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { MenuItem } from 'primeng/api';
import { ApiService } from 'src/app/core/service/api.service';
import { MessageBoxService } from 'src/app/core/service/message-box.service';
import { AuthenticationService } from 'src/app/core/service/authentication.service';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/core/service/app.config.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, lastValueFrom } from 'rxjs';

import { AppConstant } from '@config/app.config';
@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent implements OnInit {
    items: MenuItem[];
    session;
    cities = [
        { name: 'PT. Kramayudha Ratu Motor', code: 'NY' },
        { name: 'PT. Lawencon International', code: 'RM' },
    ];
    language = [
        { label: 'EN', value: 'en' },
        { label: 'ID', value: 'id' },
    ];
    selectedLang = localStorage.getItem('locale') || 'en';
    companyId;
    constructor(
        public appMain: AppMainComponent,
        public api: ApiService,
        public msg: MessageBoxService,
        private auth: AuthenticationService,
        private route: Router,
        private config: ConfigService,
        public translate: TranslateService,
    ) {}
    ngOnInit(): void {
        this.session = this.auth.getSession();
        this.defaultLangChecker();
        // this.getDataCompany();
    }
    doLogout() {
        this.auth.destroySession();
        this.route.navigate(['/login']);
        // this.api.logout().subscribe((res) => {
        //     this.auth.destroySession();
        // });
        // if (environment.isSSO) {
        //     this.auth.destroySession();
        //     window.location.href = environment.linovWordpress;
        // } else {
        //     this.route.navigate(['/login']);
        // }
    }
    selectCompany(e) {
        if (this.session.selectedCompanyId != e.id) {
            this.config.setLoading(true);
            this.api
                .post('auth/change-company', { id: e.id }, true)
                .subscribe((res) => {
                    this.auth.createSession(res);
                    this.session = this.auth.getSession();
                    this.config.setLoading(false);
                });
        }
    }
    defaultLangChecker() {
        this.translate.addLangs(['en', 'id']);

        if (localStorage.getItem('locale')) {
            const browserLang = localStorage.getItem('locale');
            this.translate.use(browserLang.match(/en|id/) ? browserLang : 'en');
        } else {
            localStorage.setItem('locale', 'en');
            this.translate.setDefaultLang('en');
        }
    }
    changeLang(language: string) {
        localStorage.setItem('locale', language);
        this.translate.use(language);
    }

    onChangeWeb(web) {
        this.route.navigate(['/' + web]);
    }
}
