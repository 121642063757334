import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule,
} from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MessageService } from "primeng/api";
import { ToastModule } from "primeng/toast";
import { AppRoutingModule } from "./app-routing.module";

import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox";
import { DialogModule } from "primeng/dialog";
import { TableModule } from "primeng/table";
import { TabViewModule } from "primeng/tabview";
import { AppComponent } from "./app.component";
import { AppService } from "./app.service";
import { DashboardModule } from "./core/dashboard/dashboard.module";
import { HttpErrorInterceptor } from "./core/interceptor/http-error.interceptor";
import { LayoutModule } from "./layout/layout.module";
import { AuthForgotPasswordSentModule } from "./pages/auth-forgot-password-sent/auth-forgot-password-sent.module";
import { AuthForgotPasswordModule } from "./pages/auth-forgot-password/auth-forgot-password.module";
import { AuthLoginModule } from "./pages/auth-login/auth-login.module";
import { AuthRegisterModule } from "./pages/auth-register/auth-register.module";
import { AuthResetPasswordModule } from "./pages/auth-reset-password/auth-reset-password.module";

export const interceptorProviders = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpErrorInterceptor,
        multi: true,
    },
];
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/translations/", ".json");
}
@NgModule({
    imports: [
        AppRoutingModule,
        BrowserModule,
        TranslateModule.forRoot({
            defaultLanguage: "en",
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        BrowserAnimationsModule,
        HttpClientModule,
        LayoutModule,
        ToastModule,
        DashboardModule,
        TabViewModule,
        DialogModule,
        ButtonModule,
        TableModule,
        CheckboxModule,
        AuthLoginModule,
        AuthRegisterModule,
        AuthForgotPasswordModule,
        AuthForgotPasswordSentModule,
        AuthResetPasswordModule,
    ],
    declarations: [AppComponent],
    providers: [MessageService, interceptorProviders, AppService],
    bootstrap: [AppComponent],
})
export class AppModule {}
