<div class="hide-mobile">
    <div
        class="layout-topbar flex justify-content-between px-8"
        style="background-color: white"
    >
        <div>
            <a href="/" style="color: #085576">
                <div style="display: flex">
                    <div style="font-weight: 900; font-size: 24px">
                        JOB Portal
                    </div>
                    <div
                        style="
                            font-weight: 600;
                            font-size: 14px;
                            margin-left: 4px;
                        "
                    >
                        by LinovHR
                    </div>
                </div>
            </a>
        </div>
        <div class="flex">
            <a
                href="/find-jobs"
                class="p-link"
                style="
                    font-weight: 400;
                    font-size: 16px;
                    margin-left: 20px;
                    margin-right: 20px;
                "
                >Find Jobs</a
            >
            <a
                *ngIf="isLogin === true && isCandidate === true"
                href="/application"
                class="p-link"
                style="
                    font-weight: 400;
                    font-size: 16px;
                    margin-left: 20px;
                    margin-right: 20px;
                "
                >Application</a
            >
            <a
                *ngIf="isLogin === true && isCandidate === true"
                href="/saved-jobs"
                class="p-link"
                style="
                    font-weight: 400;
                    font-size: 16px;
                    margin-left: 20px;
                    margin-right: 20px;
                "
                >Saved Jobs</a
            >
        </div>
        <div class="flex align-items-center">
            <!-- <a href="#" class="mr-3">
                <i
                    class="fas fa-bell"
                    style="font-size: 20px; color: #7b7b7b"
                ></i>
            </a> -->
            <div class="button-sign">
                <button pButton label="Sign Up" [routerLink]="['/register']" *ngIf="isLogin === false" class="p-link text-primary mx-1 p-2" style="background-color: #e6f4ff"></button>
                <button pButton label="Sign In" [routerLink]="['/login']" *ngIf="isLogin === false" class="p-link mx-1 bg-primary p-2"></button>
            </div>
            <!-- <a href="/login" *ngIf="isLogin === false" class="p-link">
                <img
                src="assets/images/sample-user-image.jpg"
                style="width: 30px; height: 30px; border-radius: 100%"
            />
                <i
                    class="fas fa-user"
                    style="font-size: 20px; color: #7b7b7b"
                ></i>
            </a> -->
            <a
                *ngIf="isLogin === true"
                class="p-link"
                (click)="appMain.toggleTopMenu($event)"
            >
                <!-- <img
                    src="assets/images/sample-user-image.jpg"
                    style="width: 30px; height: 30px; border-radius: 100%"
                /> -->
                <i
                    class="fas fa-user"
                    style="font-size: 20px; color: #7b7b7b"
                ></i>
            </a>
            <a
                class="p-link layout-topbar-menu-button"
                href="#"
                (click)="appMain.toggleTopMenu($event)"
            >
                <i class="fas fa-bars" style="color: #7b7b7b"></i>
            </a>
        </div>
        <div
            class="layout-topbar-menu"
            [ngClass]="{
                'layout-topbar-menu-mobile-active': appMain.topMenuActive
            }"
        >
            <div class="account-menu">
                <div class="flex align-items-center mb-1 ml-3">
                    <!-- <img
                        src="https://cdn.pixabay.com/photo/2015/20/05/22/37/blank-profile-picture-973460_640.png"
                        style="width: 4rem; height: 4rem; border-radius: 50%"
                    /> -->
                    <div class="flex-column ml-4">
                        <!-- <span class="span-username">{{
                            candidate.fullName ? candidate.fullName : "-"
                        }}</span> -->
                        <p class="mb-1"></p>
                        <a [href]="'/profile'">
                            <button
                                pButton
                                pRipple
                                label="View Profile"
                                class="btn-view-profile"
                            ></button>
                        </a>
                    </div>
                </div>
                <ul>
                    <!-- <li>
                        <i class="fas fa-user-gear"></i>
                        <a href="/profile">Account Settings</a>
                    </li> -->
                    <li
                        (click)="doLogout(); appMain.toggleTopMenu($event)"
                        style="
                            border-top: 1px solid var(--surface-200);
                            border-bottom-left-radius: 6px;
                            border-bottom-right-radius: 6px;
                        "
                    >
                        <i
                            class="fas fa-arrow-right-from-bracket"
                            style="
                                color: var(--primary-red);
                                font-size: 1.3rem;
                                font-weight: 700;
                            "
                        ></i>
                        <a
                            href="#"
                            style="color: var(--primary-red); font-weight: 500"
                            >Log Out</a
                        >
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="hide-desktop">
    <div
        class="layout-topbar flex justify-content-between"
        style="background-color: white"
    >
        <div>
            <a class="p-link" style="color: #085576">
                <div style="display: flex">
                    <div style="font-weight: 900; font-size: 24px">
                        JOB Portal
                    </div>
                    <div
                        style="
                            font-weight: 600;
                            font-size: 14px;
                            margin-left: 4px;
                        "
                    >
                        by LinovHR
                    </div>
                </div>
            </a>
        </div>
        <div class="flex align-items-center">
            <!-- <a href="#" class="mr-3">
                <i
                    class="fas fa-bell"
                    style="font-size: 20px; color: #7b7b7b"
                ></i>
            </a> -->
            <a
                class="p-link layout-topbar-menu-button"
                href="#"
                (click)="appMain.toggleTopMenu($event)"
            >
                <i class="fas fa-bars" style="color: #7b7b7b"></i>
            </a>
        </div>
        <div
            class="layout-topbar-menu"
            [ngClass]="{
                'layout-topbar-menu-mobile-active': appMain.topMenuActive
            }"
        >
            <div class="account-menu">
                <div class="flex align-items-center mb-1 ml-3">
                    <!-- <img
                        src="https://cdn.pixabay.com/photo/2015/20/05/22/37/blank-profile-picture-973460_640.png"
                        style="width: 4rem; height: 4rem; border-radius: 50%"
                    /> -->
                    <div class="flex-column ml-4">
                        <!-- <span class="span-username">{{
                            candidate.fullName ? candidate.fullName : "-"
                        }}</span> -->
                        <p class="mb-1"></p>
                        <a [href]="'/profile'">
                            <button
                                pButton
                                pRipple
                                label="View Profile"
                                class="btn-view-profile"
                            ></button>
                        </a>
                    </div>
                </div>
                <ul>
                    <!-- <li>
                        <i class="fas fa-user-gear"></i>
                        <a href="/login">Account Settings</a>
                    </li> -->
                    <li
                        (click)="doLogout(); appMain.toggleTopMenu($event)"
                        style="
                            border-top: 1px solid var(--surface-200);
                            border-bottom-left-radius: 6px;
                            border-bottom-right-radius: 6px;
                        "
                    >
                        <i
                            class="fas fa-arrow-right-from-bracket"
                            style="
                                color: var(--primary-red);
                                font-size: 1.3rem;
                                font-weight: 700;
                            "
                        ></i>
                        <a
                            href="#"
                            style="color: var(--primary-red); font-weight: 500"
                            >Log Out</a
                        >
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
