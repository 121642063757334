import { Injectable } from "@angular/core";
import { ApiService } from "@core/service/api.service";

@Injectable({
    providedIn: "root",
})
export class AuthResetPasswordServiceService {
    constructor(private api: ApiService) {}

    resetPassword(body) {
        return this.api.putWithoutToken(`users/reset-password`, body);
    }
}
