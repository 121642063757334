<p-dialog
    class="message-error"
    [(visible)]="isMessageSuccess"
    [style]="{ width: '500px' }"
>
    <div class="c-icons approved-icon"></div>
    <h3>Success</h3>
    <p>{{ message }}</p>

    <ng-template pTemplate="footer">
        <button
            type="button"
            pButton
            label="OK"
            (click)="clickBack()"
            [style]="{ width: '224px' }"
        ></button>
    </ng-template>
</p-dialog>
