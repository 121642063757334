<flash-message-error></flash-message-error>
<flash-message-success></flash-message-success>
<div class="hide-mobile">
    <div class="flex">
        <div class="left">
            <banner-section></banner-section>
        </div>
        <div class="right flex justify-content-center">
            <form class="form-desktop" [formGroup]="form" (ngSubmit)="submit()">
                <div class="mb-5 title"><b>Sign In to Job Portal</b></div>
                <div class="flex flex-column mb-4">
                    <label class="label">Username</label>
                    <input
                        pInputText
                        placeholder="Input Username"
                        [(ngModel)]="username"
                        formControlName="username"
                    />
                    <div
                        *ngIf="f['username'].touched && f['username'].invalid"
                        class="validation-message"
                    >
                        <div
                            *ngIf="
                                f['username'].errors &&
                                f['username'].errors['required']
                            "
                        >
                            Username is required.
                        </div>
                    </div>
                </div>
                <div class="flex flex-column mb-4">
                    <label class="label">Password</label>
                    <p-password
                        class="p-fluid"
                        [feedback]="false"
                        [toggleMask]="true"
                        placeholder="Input password"
                        [(ngModel)]="password"
                        formControlName="password"
                    ></p-password>
                    <div
                        *ngIf="f['password'].touched && f['password'].invalid"
                        class="validation-message"
                    >
                        <div
                            *ngIf="
                                f['password'].errors &&
                                f['password'].errors['required']
                            "
                        >
                            Password is required.
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <p-checkbox
                        [(ngModel)]="checked"
                        [binary]="true"
                        label="Remember Me"
                    ></p-checkbox>
                </div>
                <div class="mb-4">
                    <div class="p-fluid">
                        <button
                            pButton
                            pRipple
                            label="Login"
                            class="btn"
                            [ngClass]="{'bg-primary': form.valid}"
                            [loading]="isLoading"
                            [disabled]="!form.valid"
                        ></button>
                    </div>
                </div>
                <div class="text-center">
                    Don’t have an account? Sign Up
                    <a href="/register">Here</a>
                </div>
                <div class="text-center">
                    <a href="/forgot-password">Forgot Password</a>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="hide-desktop">
    <banner-section-responsive></banner-section-responsive>
    <div class="flex justify-content-center position-up relative">
        <form
            class="container-mobile form-mobile"
            [formGroup]="form"
            (ngSubmit)="submit()"
        >
            <div class="mb-5 title"><b>Sign In to Job Portal</b></div>
            <div class="flex flex-column mb-4">
                <label class="label">Usename</label>
                <input
                    pInputText
                    placeholder="Input username"
                    [(ngModel)]="username"
                    formControlName="username"
                />
                <div
                    *ngIf="f['username'].touched && f['username'].invalid"
                    class="validation-message"
                >
                    <div
                        *ngIf="
                            f['username'].errors &&
                            f['username'].errors['required']
                        "
                    >
                        Username is required.
                    </div>
                </div>
            </div>
            <div class="flex flex-column mb-4">
                <label class="label">Password</label>
                <p-password
                    class="p-fluid"
                    [feedback]="false"
                    [toggleMask]="true"
                    placeholder="Input password"
                    [(ngModel)]="password"
                    formControlName="password"
                ></p-password>
                <div
                    *ngIf="f['password'].touched && f['password'].invalid"
                    class="validation-message"
                >
                    <div
                        *ngIf="
                            f['password'].errors &&
                            f['password'].errors['required']
                        "
                    >
                        Password is required.
                    </div>
                </div>
            </div>
            <div class="mb-4">
                <p-checkbox label="Remember Me"></p-checkbox>
            </div>
            <div class="mb-4">
                <div class="p-fluid">
                    <button
                        pButton
                        pRipple
                        label="Login"
                        class="btn"
                        [disabled]="!form.valid"
                    ></button>
                </div>
            </div>
            <div class="text-center">
                Don’t have an account? Sign Up
                <a href="/register">Here</a>
            </div>
            <div class="text-center">
                <a href="/forgot-password">Forgot Password</a>
            </div>
        </form>
    </div>
</div>
