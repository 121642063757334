import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';

@Component({
    selector: 'app-error-page-forbidden-found',
    templateUrl: 'page-forbidden.component.html',
    styleUrls: ['./page-forbidden.component.scss'],
})
export class PageForbiddenComponent implements OnInit {
    constructor(private router: Router) { }

    ngOnInit(): void {
    }

    onReturnHome(): void {
        this.router.navigate(['']);
    }
}
