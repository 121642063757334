import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FlashMessageModule } from "@shared/flash-message/flash-message.module";
import { NgxSpinnerModule } from "ngx-spinner";
import { ButtonModule } from "primeng/button";
import { CarouselModule } from "primeng/carousel";
import { GalleriaModule } from "primeng/galleria";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { MenubarModule } from "primeng/menubar";
import { MultiSelectModule } from "primeng/multiselect";
import { PasswordModule } from "primeng/password";
import { ScrollTopModule } from "primeng/scrolltop";
import { TieredMenuModule } from "primeng/tieredmenu";
import { ConfigService } from "../core/service/app.config.service";
import { SharedComponentModule } from "../core/shared-component/shared-component.module";
import { MenuService } from "../showcase/service/app.menu.service";
import { AppConfigComponent } from "./component/app.config.component";
import { AppFooterComponent } from "./component/app.footer.component";
import { AppMainComponent } from "./component/app.main.component";
import { AppMenuComponent } from "./component/app.menu.component";
import { AppMenuitemComponent } from "./component/app.menuitem.component";
import { AppSubMenuComponent } from "./component/app.submenu.component";
import { AppTopBarComponent } from "./component/app.topbar.component";
import { PageForbiddenComponent } from "./page-forbidden/page-forbidden.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { RedirectComponent } from "./redirect/redirect.component";
// --- JobPortal-Layout ---
import { MainComponent } from "./main/main.component";
import { MainFooterComponent } from "./main/main.footer.component";
import { MainTopBarComponent } from "./main/main.topbar.component";
// --- JobPortal-Layout ---
@NgModule({
    declarations: [
        AppMainComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppConfigComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        PageNotFoundComponent,
        PageForbiddenComponent,
        RedirectComponent,
        AppSubMenuComponent,
        // --- JobPortal-Layout ---
        MainComponent,
        MainTopBarComponent,
        MainFooterComponent,
        // --- JobPortal-Layout ---
    ],
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        InputSwitchModule,
        ButtonModule,
        NgxSpinnerModule,
        InputTextModule,
        PasswordModule,
        ReactiveFormsModule,
        SharedComponentModule,
        GalleriaModule,
        MenubarModule,
        MultiSelectModule,
        CarouselModule,
        ScrollTopModule,
        TieredMenuModule,
        FlashMessageModule,
    ],
    providers: [MenuService, ConfigService],
})
export class LayoutModule {}
