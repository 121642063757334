import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthForgotPasswordComponent } from "./auth-forgot-password.component";
import { FlashMessageModule } from "@core/shared-component/flash-message/flash-message.module";
import { BannerSectionModule } from "src/app/components/organisms/auth/banner-section/banner-section.module";
import { BannerSectionResponsiveModule } from "src/app/components/organisms/auth/banner-section-responsive/banner-section-responsive.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";
import { ButtonModule } from "primeng/button";

@NgModule({
    declarations: [AuthForgotPasswordComponent],
    imports: [
        CommonModule,
        FormsModule,
        FlashMessageModule,
        BannerSectionModule,
        BannerSectionResponsiveModule,
        ReactiveFormsModule,
        InputTextModule,
        ButtonModule,
    ],
})
export class AuthForgotPasswordModule {}
