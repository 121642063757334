<flash-message-error></flash-message-error>
<flash-message-success></flash-message-success>
<div class="hide-mobile">
    <div class="flex">
        <div class="left">
            <banner-section></banner-section>
        </div>
        <div class="right flex justify-content-center">
            <form class="form-desktop" [formGroup]="form" (ngSubmit)="submit()">
                <div class="mb-5 title"><b>Register to Job Portal</b></div>
                <div class="flex flex-column mb-4">
                    <label class="label">Username</label>
                    <input
                        pInputText
                        placeholder="Input username"
                        formControlName="username"
                    />
                    <div
                        *ngIf="f['username'].touched && f['username'].invalid"
                        class="validation-message"
                    >
                        <div
                            *ngIf="
                                f['username'].errors &&
                                f['username'].errors['required']
                            "
                        >
                            Username is required.
                        </div>
                    </div>
                </div>
                <div class="flex flex-column mb-4">
                    <label class="label">Email Address</label>
                    <input
                        pInputText
                        placeholder="Input email address"
                        formControlName="email"
                        [(ngModel)]="email"
                    />
                    <div
                        *ngIf="f['email'].touched && f['email'].invalid"
                        class="validation-message"
                    >
                        <div
                            *ngIf="
                                f['email'].errors &&
                                f['email'].errors['required']
                            "
                        >
                            Email is required.
                        </div>
                    </div>
                </div>
                <div class="flex flex-column mb-4">
                    <label class="label">Password</label>
                    <p-password
                        (keyup)="onChange($event)"
                        class="p-fluid mb-3"
                        [feedback]="false"
                        [toggleMask]="true"
                        placeholder="Input password"
                        formControlName="password"
                        [(ngModel)]="password"
                    ></p-password>
                    <div
                        *ngIf="f['password'].touched && f['password'].invalid"
                        class="validation-message"
                    >
                        <div
                            *ngIf="
                                f['password'].errors &&
                                f['password'].errors['required']
                            "
                        >
                            Password is required.
                        </div>
                    </div>

                    <div *ngIf="min8Char === false" className="grid" style="color: #e1e1e1">
                        <span style="color: #7b7b7b"> Min. 8 characters </span>
                    </div>
                    <div *ngIf="min8Char === true" className="grid">
                        <i class="fa-solid fa-circle-check"></i>
                        <span style="color: #7b7b7b"> Min. 8 characters </span>
                    </div>
                    <div *ngIf="lettNNum === false" className="grid" style="color: #e1e1e1">
                        <span style="color: #7b7b7b"> Consists of letters & numbers </span>
                    </div>
                    <div *ngIf="lettNNum === true" className="grid">
                        <i class="fa-solid fa-circle-check"></i>
                        <span style="color: #7b7b7b"> Consists of letters & numbers </span>
                    </div>
                    <div *ngIf="capitalSmallLetters === false" className="grid" style="color: #e1e1e1">
                        <span style="color: #7b7b7b"> Consists of capital & small letters </span>
                    </div>
                    <div *ngIf="capitalSmallLetters === true" className="grid">
                        <i class="fa-solid fa-circle-check"></i>
                        <span style="color: #7b7b7b"> Consists of capital & small letters </span>
                    </div>
                    
                </div>
                <div class="flex flex-column mb-4">
                    <label class="label">Confirm Password</label>
                    <p-password
                        class="p-fluid"
                        [feedback]="false"
                        [toggleMask]="true"
                        placeholder="Re-enter password"
                        formControlName="password_confirmation"
                    ></p-password>
                    <div
                        *ngIf="
                            f['password_confirmation'].touched &&
                            f['password_confirmation'].invalid
                        "
                        class="validation-message"
                    >
                        <div
                            *ngIf="
                                f['password_confirmation'].errors &&
                                f['password_confirmation'].errors['required']
                            "
                        >
                            Confirm Password is required.
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <p-checkbox
                        [(ngModel)]="checked"
                        [binary]="true"
                        label="I agree to Terms and Conditions"
                    ></p-checkbox>
                </div>
                <div class="mb-4">
                    <div class="p-fluid">
                        <button
                            pButton
                            pRipple
                            label="Login"
                            class="btn"
                            [disabled]="!form.valid"
                        ></button>
                    </div>
                </div>
                <div class="text-center">
                    Already have an Account? Sign In
                    <a href="/login">Here</a>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="hide-desktop">
    <banner-section-responsive></banner-section-responsive>
    <div class="flex justify-content-center position-up relative">
        <div class="justify-content-center">
            <form
                class="container-mobile form-mobile"
                [formGroup]="form"
                (ngSubmit)="submit()"
            >
                <div class="mb-5 title"><b>Register to Job Portal</b></div>
                <div class="flex flex-column mb-4">
                    <label class="label">Username</label>
                    <input
                        pInputText
                        placeholder="Input username"
                        formControlName="username"
                    />
                    <div
                        *ngIf="f['username'].touched && f['username'].invalid"
                        class="validation-message"
                    >
                        <div
                            *ngIf="
                                f['username'].errors &&
                                f['username'].errors['required']
                            "
                        >
                            Username is required.
                        </div>
                    </div>
                </div>
                <div class="flex flex-column mb-4">
                    <label class="label">Email Address</label>
                    <input
                        pInputText
                        placeholder="Input email address"
                        [(ngModel)]="email"
                        formControlName="email"
                    />
                    <div
                        *ngIf="f['email'].touched && f['email'].invalid"
                        class="validation-message"
                    >
                        <div
                            *ngIf="
                                f['email'].errors &&
                                f['email'].errors['required']
                            "
                        >
                            Email is required.
                        </div>
                    </div>
                </div>
                <div class="flex flex-column mb-4">
                    <label class="label">Password</label>
                    <p-password
                        class="p-fluid"
                        [feedback]="false"
                        [toggleMask]="true"
                        placeholder="Input password"
                        formControlName="password"
                        [(ngModel)]="password"
                    ></p-password>
                    <div
                        *ngIf="f['password'].touched && f['password'].invalid"
                        class="validation-message"
                    >
                        <div
                            *ngIf="
                                f['password'].errors &&
                                f['password'].errors['required']
                            "
                        >
                            Password is required.
                        </div>
                    </div>
                </div>
                <div class="flex flex-column mb-4">
                    <label class="label">Confirm Password</label>
                    <p-password
                        class="p-fluid"
                        [feedback]="false"
                        [toggleMask]="true"
                        placeholder="Re-enter password"
                        formControlName="password_confirmation"
                    ></p-password>
                    <div
                        *ngIf="
                            f['password_confirmation'].touched &&
                            f['password_confirmation'].invalid
                        "
                        class="validation-message"
                    >
                        <div
                            *ngIf="
                                f['password_confirmation'].errors &&
                                f['password_confirmation'].errors['required']
                            "
                        >
                            Confirm Password is required.
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <p-checkbox
                        label="I aggre to Terms and Conditions"
                    ></p-checkbox>
                </div>
                <div class="mb-4">
                    <div class="p-fluid">
                        <button
                            pButton
                            pRipple
                            label="Register"
                            class="btn"
                            [disabled]="!form.valid"
                        ></button>
                    </div>
                </div>
                <div class="text-center">
                    Already have an Account? Sign In
                    <a href="/login">Here</a>
                </div>
            </form>
        </div>
    </div>
</div>
