import { Injectable } from "@angular/core";
import { ApiService } from "@core/service/api.service";

@Injectable({
    providedIn: "root",
})
export class MainServiceService {
    constructor(private api: ApiService) {}

    getCandidate() {
        return this.api.get(
            `recruitment/candidates`,
            null,
            true,
            null,
            null,
            true
        );
    }
}
