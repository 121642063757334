import { isNil } from 'lodash-es';
import { PageInfo } from '../model/page-info';
import { NgControl } from '@angular/forms';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatorState } from 'primeng/paginator';
import { AbstractControl } from '@angular/forms';
import { MessageBoxService } from './message-box.service';
import { base64StringToBlob } from 'blob-util';
import { AuthenticationService } from './authentication.service';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HelperService {
    constructor(
        private readonly _msg: MessageBoxService,
        private readonly _auth: AuthenticationService,
        private readonly _apiService: ApiService,
        private readonly _domSanitizer: DomSanitizer
    ) {}

    getParam(data: PageInfo, body?: any) {
        let param = new HttpParams()
            .set('page', data.pageInfo)
            // .set('limit', data.pageLimit)
            .set('pageSize', data.pageLimit);
        // .set('tenantId', '007');
        if (body) {
            Object.keys(body).forEach((k) => {
                if (body[k] == false || (body[k] && k !== 'isRefresh')) {
                    param = param.set(k, body[k]);
                }
            });
        }

        return param;
    }

    formatDate(a: Object, timeZone?, customFormat?) {
        let formatStr = 'yyyy-MM-dd';
        if (customFormat) {
            formatStr = customFormat;
        }

        return this.formatAll(a, formatStr, timeZone);
    }

    formatTime(a: Object, timeZone?, customFormat?) {
        let formatStr = 'HH:mm';
        if (customFormat) {
            formatStr = customFormat;
        }
        return this.formatAll(a, formatStr, timeZone);
    }

    formatDateAndTime(a: Object, timeZone?, customFormat?) {
        let formatStr = 'yyyy-MM-dd HH:mm';
        if (customFormat) {
            formatStr = customFormat;
        }
        return this.formatAll(a, formatStr, timeZone);
    }

    formatDateAndTimeT(a: Object, timeZone?, customFormat?) {
        let formatStr = "yyyy-MM-dd'T'HH:mm";
        if (customFormat) {
            formatStr = customFormat;
        }
        let result = this.formatAll(a, formatStr, timeZone);
        return result;
    }

    formatDateWithComa(inputDate) {
        const date = new Date(inputDate);

        let dateOptions = {
            year: 'numeric' as const,
            month: '2-digit' as const,
            day: '2-digit' as const,
            hour: '2-digit' as const,
            minute: '2-digit' as const,
            hour12: false,
            timeZone: 'UTC'
        };

        const formattedDate = date.toLocaleString('en-US', dateOptions);
        const [datePart, timePart] = formattedDate.split(', ');
        return `${datePart}, ${timePart}`;
    }


    formatAll(a, formatStr, timeZone) {
        if (a instanceof Date) {
            let date = timeZone
                ? new Date(
                    new Date(a).toLocaleString('en', {
                        timeZone: 'Asia/Jakarta',
                    })
                )
                : a;
            a = a ? format(date, formatStr) : null;
        } else if (a instanceof Object) {
            Object.keys(a).forEach((k) => {
                if (a[k] && a[k] instanceof Date) {
                    let date = timeZone
                        ? new Date(
                            new Date(a[k]).toLocaleString('en', {
                                timeZone: 'Asia/Jakarta',
                            })
                        )
                        : a[k];
                    a[k] = a[k] ? format(date, formatStr) : null;
                }
            });
        }
        return a;
    }

    convertDateToDateMonthYear(date: string) {
        const dateObject = new Date(date);

        const options: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        };
        const formattedDate = dateObject.toLocaleDateString('en-GB', options);

        return formattedDate; //output: "1 Jan 2023"
    }

    convertTimeStringToDateTime(time) {
        return new Date('01/01/2022 ' + time + ' GMT+0700');
    }

    convertServerDateTimeToLocalDateTime(dateTime, attendance?) {
        dateTime = dateTime.replaceAll('-', '/');

        if (attendance) {
            return new Date(dateTime + ' GMT+0000');
        }

        return new Date(dateTime + ' GMT+0700');
    }

    getStatus(data) {
        let status, statusLabel;
        if (data?.status) {
            status = data?.status.toLowerCase();
            statusLabel = status;
        } else if (!isNil(data?.isActive)) {
            status = data?.isActive;
            statusLabel = data?.isActive ? 'Active' : 'Inactive';
        } else if (data?.accountBankStatus) {
            status = data?.accountBankStatus.toLowerCase();
            statusLabel = status;
        } else if (data?.statusRequest) {
            status = data?.statusRequest.toLowerCase();
            statusLabel = status;
        }

        return {
            statusCode: {
                'span-blue': ['open', 'processed'].includes(status),
                'span-gray': ['not exist'].includes(status),
                'span-yellow': [
                    'pulang awal',
                    'pending',
                    'draft',
                    'not exist',
                    'rollback',
                ].includes(status),
                'span-active-secondary': [
                    'active',
                    'hadir',
                    'approved',
                    'publish',
                    'published',
                    'reviewed',
                    true,
                ].includes(status),
                'span-inactive-secondary': [
                    'inactive',
                    'tidak hadir',
                    'terlambat',
                    'rejected',
                    'canceled',
                    'unprocessed',
                    false,
                ].includes(status),
            },
            statusLabel: statusLabel,
        };
    }

    download(obj: Object);
    download(base: string, filename: string, ext: string);
    download(a, b?, c?) {
        const base = a.base ? a.base : a;
        const fileName = a.filename ? a.filename : b;
        const ext = a.ext ? a.ext : c;
        const file = new Blob([base64StringToBlob(base)], {
            type: 'octet/stream',
        });
        saveAs(file, fileName + (ext.includes('.') ? ext : '.' + ext));
    }

    downloadFile(resp, base = 'base', filename = 'filename', ext = 'ext') {
        console.log(resp);
        var bs = atob(resp[base]);

        var buffer = new ArrayBuffer(bs.length);
        var ba = new Uint8Array(buffer);
        for (var i = 0; i < bs.length; i++) {
            ba[i] = bs.charCodeAt(i);
        }
        var file = new Blob([ba], { type: 'octet/stream' });

        saveAs(
            file,
            resp[filename] +
            (resp[ext].includes('.') ? resp[ext] : '.' + resp[ext])
        );
    }

    checkStat(noNotif?) {
        let stat = this._auth.getSession().subStat;
        if (stat == 'R96aPGLRUHso0Oixc3/PQA==') {
            return true;
        } else {
            if (!noNotif)
                this._msg.showInfo(
                    'Silahkan aktivasi akun anda untuk menggunakan fitur ini'
                );
            return false;
        }
    }

    convertInputArraysToString(arr, label = 'label') {
        const values = arr.map((obj) => obj[label]);
        return values.join('');
    }

    public isInvalidControl(
        control: NgControl | AbstractControl,
        customTouched?: boolean | undefined
    ): boolean {
        if (!control) {
            return false;
        }

        const { dirty, invalid } = control;

        return invalid && (dirty || (customTouched ?? control.touched));
    }

    public getStartAndEndIndex(pageInfo: PaginatorState): {
        endIndex: number;
        startIndex: number;
    } {
        const startIndex: number = pageInfo.rows * pageInfo.page;
        const endIndex: number = startIndex + pageInfo.rows;

        return {
            endIndex,
            startIndex,
        };
    }

    public async getEmployeePhoto(id: string): Promise<SafeUrl | undefined> {
        let image: SafeUrl = '/assets/no-profile-picture.svg';

        try {
            const res: any = await lastValueFrom(
                this._apiService.getFileByte(`employees/${id}/active-photo`)
            );
            const objectURL: string = URL.createObjectURL(res);

            image = this._domSanitizer.bypassSecurityTrustUrl(objectURL);
        } catch (error) {}

        return image;
    }
    
}
