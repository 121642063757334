import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { OnInit, Component } from '@angular/core';

@Component({
    selector: 'app-error-page-not-found',
    templateUrl: 'page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
    constructor(
        private readonly _router: Router,
        private readonly _appService: AppService
    ) {}

    public ngOnInit(): void {
        if (this._appService.isLoading$.value) {
            this._appService.isLoading$.next(false);
        }
    }

    public onReturnHome(): void {
        this._router.navigate(['']);
    }
}
