import { Component } from "@angular/core";

@Component({
    selector: "footer",
    templateUrl: "./main.footer.component.html",
})
export class MainFooterComponent {
    anio: number = new Date().getFullYear();

    backToTop() {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }
}
