<flash-message-error></flash-message-error>
<flash-message-success></flash-message-success>
<div class="hide-mobile">
    <div class="flex">
        <div class="left">
            <banner-section></banner-section>
        </div>
        <div class="right flex justify-content-center">
            <form class="form-desktop" [formGroup]="form" (ngSubmit)="submit()">
                <div class="mb-3 title"><b>Create a New Password</b></div>
                <div class="mb-3">
                    Please enter a new password for your account
                </div>
                <div class="flex flex-column mb-4">
                    <label class="label">Password</label>
                    <p-password
                        [(ngModel)]="password"
                        (keyup)="onChange($event)"
                        class="p-fluid mb-3"
                        [feedback]="false"
                        [toggleMask]="true"
                        placeholder="Input password"
                        formControlName="password"
                    ></p-password>
                    <div
                        *ngIf="f['password'].touched && f['password'].invalid"
                        class="validation-message"
                    >
                        <div
                            *ngIf="
                                f['password'].errors &&
                                f['password'].errors['required']
                            "
                        >
                            Password is required.
                        </div>
                    </div>
                    <div
                        *ngIf="min8Char === false"
                        className="grid"
                        style="color: #e1e1e1"
                    >
                        <i class="fa-solid fa-circle-check"></i>
                        <span style="color: #7b7b7b"> Min. 8 characters </span>
                    </div>
                    <div *ngIf="min8Char === true" className="grid">
                        <i class="fa-solid fa-circle-check"></i>
                        <span style="color: #7b7b7b"> Min. 8 characters </span>
                    </div>
                    <div
                        *ngIf="lettNNum === false"
                        className="grid"
                        style="color: #e1e1e1"
                    >
                        <i class="fa-solid fa-circle-check"></i>
                        <span style="color: #7b7b7b">
                            Consists of letters & numbers</span
                        >
                    </div>
                    <div *ngIf="lettNNum === true" className="grid">
                        <i class="fa-solid fa-circle-check"></i>
                        <span style="color: #7b7b7b">
                            Consists of letters & numbers</span
                        >
                    </div>
                    <div
                        *ngIf="lettNNum === false"
                        className="grid"
                        style="color: #e1e1e1"
                    >
                        <i class="fa-solid fa-circle-check"></i>
                        <span style="color: #7b7b7b">
                            Consists of capital & small letters</span
                        >
                    </div>
                    <div *ngIf="lettNNum === true" className="grid">
                        <i class="fa-solid fa-circle-check"></i>
                        <span style="color: #7b7b7b">
                            Consists of special characters</span
                        >
                    </div>
                </div>
                <div class="flex flex-column mb-4">
                    <label class="label">Confirm Password</label>
                    <p-password
                        class="p-fluid"
                        [feedback]="false"
                        [toggleMask]="true"
                        placeholder="Re-enter password"
                        formControlName="password_confirmation"
                    ></p-password>
                </div>
                <div class="mb-4">
                    <div class="p-fluid">
                        <button
                            pButton
                            pRipple
                            label="Reset Password"
                            class="btn"
                            [disabled]="!form.valid"
                        ></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="hide-desktop">
    <banner-section-responsive></banner-section-responsive>
    <div class="flex justify-content-center position-up relative">
        <div class="justify-content-center">
            <form
                class="container-mobile form-mobile"
                [formGroup]="form"
                (ngSubmit)="submit()"
            >
                <div class="mb-3 title"><b>Create a New Password</b></div>
                <div class="mb-3">
                    Please enter a new password for your account
                </div>
                <div class="flex flex-column mb-4">
                    <label class="label">Password</label>
                    <p-password
                        class="p-fluid"
                        [feedback]="false"
                        [toggleMask]="true"
                        placeholder="Input password"
                        formControlName="password"
                    ></p-password>
                    <div
                        *ngIf="f['password'].touched && f['password'].invalid"
                        class="validation-message"
                    >
                        <div
                            *ngIf="
                                f['password'].errors &&
                                f['password'].errors['required']
                            "
                        >
                            Password is required.
                        </div>
                    </div>
                    <div
                        *ngIf="min8Char === false"
                        className="grid"
                        style="color: #e1e1e1"
                    >
                        <i class="fa-solid fa-circle-check"></i>
                        <span style="color: #7b7b7b"> Min. 8 characters </span>
                    </div>
                    <div *ngIf="min8Char === true" className="grid">
                        <i class="fa-solid fa-circle-check"></i>
                        <span style="color: #7b7b7b"> Min. 8 characters </span>
                    </div>
                    <div
                        *ngIf="lettNNum === false"
                        className="grid"
                        style="color: #e1e1e1"
                    >
                        <i class="fa-solid fa-circle-check"></i>
                        <span style="color: #7b7b7b">
                            Consists of letters & numbers</span
                        >
                    </div>
                    <div *ngIf="lettNNum === true" className="grid">
                        <i class="fa-solid fa-circle-check"></i>
                        <span style="color: #7b7b7b">
                            Consists of letters & numbers</span
                        >
                    </div>
                    <div
                        *ngIf="lettNNum === false"
                        className="grid"
                        style="color: #e1e1e1"
                    >
                        <i class="fa-solid fa-circle-check"></i>
                        <span style="color: #7b7b7b">
                            Consists of capital & small letters</span
                        >
                    </div>
                    <div *ngIf="lettNNum === true" className="grid">
                        <i class="fa-solid fa-circle-check"></i>
                        <span style="color: #7b7b7b">
                            Consists of special characters</span
                        >
                    </div>
                </div>
                <div class="flex flex-column mb-4">
                    <label class="label">Confirm Password</label>
                    <p-password
                        class="p-fluid"
                        [feedback]="false"
                        [toggleMask]="true"
                        placeholder="Re-enter password"
                        formControlName="password_confirmation"
                    ></p-password>
                    <div
                        *ngIf="
                            f['password_confirmation'].touched &&
                            f['password_confirmation'].invalid
                        "
                        class="validation-message"
                    >
                        <div
                            *ngIf="
                                f['password_confirmation'].errors &&
                                f['password_confirmation'].errors['required']
                            "
                        >
                            Confirm Password is required.
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="p-fluid">
                        <button
                            pButton
                            pRipple
                            label="Reset Password"
                            class="btn"
                            [disabled]="!form.valid"
                        ></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
