import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlashMessageModule } from "@core/shared-component/flash-message/flash-message.module";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { BannerSectionResponsiveModule } from "src/app/components/organisms/auth/banner-section-responsive/banner-section-responsive.module";
import { BannerSectionModule } from "src/app/components/organisms/auth/banner-section/banner-section.module";
import { AuthForgotPasswordSentComponent } from "./auth-forgot-password-sent.component";

@NgModule({
    declarations: [AuthForgotPasswordSentComponent],
    imports: [
        CommonModule,
        FormsModule,
        FlashMessageModule,
        BannerSectionModule,
        BannerSectionResponsiveModule,
        ReactiveFormsModule,
        InputTextModule,
        ButtonModule,
        FlashMessageModule,
    ],
})
export class AuthForgotPasswordSentModule {}
