import { AppService } from "./app.service";
import { Subscription } from "rxjs";
import { PrimeNGConfig } from "primeng/api";
import { OnInit, OnDestroy, Component } from "@angular/core";
import {
    Router,
    RouteConfigLoadEnd,
    RouteConfigLoadStart,
} from "@angular/router";
import { style, trigger, animate, transition } from "@angular/animations";

@Component({
    styles: [
        `
            .backdrop {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                background: rgba(255, 255, 255, 0.3);
                height: 100%;
                z-index: 999;
                backdrop-filter: blur(4px);
            }

            .loader {
                display: inline-block;
                width: 28px;
                height: 28px;
                border: 3px solid #1ba8dc;
                border-radius: 50%;
                border-bottom-color: transparent;
                position: fixed;
                z-index: 1000;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                animation: rotation 1s linear infinite;
            }

            @keyframes rotation {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }

            @media (width > 1280px) {
                .loader {
                    width: 42px;
                    height: 42px;
                    border-width: 4px;
                }
            }
        `,
    ],
    selector: "app-root",
    template: `
        <router-outlet></router-outlet>

        <div *ngIf="isLoading" [@fade]="isLoading">
            <div class="backdrop"></div>
            <div class="loader"></div>
        </div>

        <p-toast key="info" [baseZIndex]="9999999" styleClass="mt-7"></p-toast>
    `,
    animations: [
        trigger("fade", [
            transition("void => active", [
                style({ opacity: 0 }),
                animate(400, style({ opacity: 1 })),
            ]),
            transition("* => void", [animate(400, style({ opacity: 0 }))]),
        ]),
    ],
})
export class AppComponent implements OnInit, OnDestroy {
    public menuMode: string = "static";
    public isLoading: boolean = false;
    public isLoadingSubsriber: Subscription;

    constructor(
        private readonly _router: Router,
        private readonly _appService: AppService,
        private readonly _primengConfig: PrimeNGConfig
    ) {}

    public ngOnInit(): void {
        this._primengConfig.ripple = true;

        this.isLoading = this._appService.isLoading$.value;

        document.documentElement.style.fontSize = "14px";

        this._router.events.subscribe((event: any): void => {
            const menubar: HTMLElement = document.querySelector(".p-menubar");
            const layoutMainContainer: HTMLElement = document.querySelector(
                ".layout-main-container"
            );

            if (event instanceof RouteConfigLoadStart) {
                this._appService.isLoading$.next(true);

                if (menubar) {
                    menubar.style.visibility = "hidden";
                }

                if (layoutMainContainer) {
                    layoutMainContainer.style.opacity = "0";
                }
            }

            if (event instanceof RouteConfigLoadEnd) {
                this._appService.isLoading$.next(false);

                if (menubar) {
                    menubar.style.visibility = "visible";
                }

                if (layoutMainContainer) {
                    layoutMainContainer.style.opacity = "1";
                }
            }
        });

        this.isLoadingSubsriber = this._appService.isLoading$.subscribe(
            (value: boolean) => {
                this.isLoading = value;
            }
        );
    }

    public ngOnDestroy(): void {
        this.isLoadingSubsriber.unsubscribe();
    }
}
