import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MenuItem } from "primeng/api";
import { ApiService } from "src/app/core/service/api.service";
import { ConfigService } from "src/app/core/service/app.config.service";
import { AuthenticationService } from "src/app/core/service/authentication.service";
import { MessageBoxService } from "src/app/core/service/message-box.service";
import { MainComponent } from "./main.component";
import { lastValueFrom } from "rxjs";
import { MainServiceService } from "./main.service.service";

@Component({
    selector: "topbar",
    templateUrl: "./main.topbar.component.html",
})
export class MainTopBarComponent implements OnInit {
    items: MenuItem[];
    session;
    cities = [
        { name: "PT. Kramayudha Ratu Motor", code: "NY" },
        { name: "PT. Lawencon International", code: "RM" },
    ];
    language = [
        { label: "EN", value: "en" },
        { label: "ID", value: "id" },
    ];
    selectedLang = localStorage.getItem("locale") || "en";
    companyId;
    constructor(
        private srv: MainServiceService,
        public appMain: MainComponent,
        public api: ApiService,
        public msg: MessageBoxService,
        private auth: AuthenticationService,
        private route: Router,
        private config: ConfigService,
        public translate: TranslateService
    ) {}

    candidate: any;
    isLogin: boolean = false;
    isCandidate: boolean = false;

    ngOnInit(): void {
        if (
            JSON.parse(localStorage.getItem("job-portal"))?.token &&
            JSON.parse(localStorage.getItem("job-portal"))?.candidateId
        ) {
            this.isLogin = true;
            this.isCandidate = true;

            
            this.getCandidate();
        } else if (
            JSON.parse(localStorage.getItem("job-portal"))?.token &&
            !JSON.parse(localStorage.getItem("job-portal"))?.candidateId
        ) {
            this.isLogin = true;
            this.isCandidate = false;

        } else {
            this.isLogin = false;
            this.isCandidate = false;

        }

        // this.session = this.auth.getSession();
        // this.defaultLangChecker();
        // this.getDataCompany();
    }

    async getCandidate() {
        await lastValueFrom(this.srv.getCandidate())
            .then((res) => {
                this.candidate = res.data;
            })
            .catch((err) => {
                console.log(err);
            });
    }

    doLogout() {
        localStorage.removeItem("job-portal");
        this.auth.destroySession();
        this.route.navigate(["/login"]);
        // this.api.logout().subscribe((res) => {
        //     this.auth.destroySession();
        // });
        // if (environment.isSSO) {
        //     this.auth.destroySession();
        //     window.location.href = environment.linovWordpress;
        // } else {
        //     this.route.navigate(['/login']);
        // }
    }
    selectCompany(e) {
        if (this.session.selectedCompanyId != e.id) {
            this.config.setLoading(true);
            this.api
                .post("auth/change-company", { id: e.id }, true)
                .subscribe((res) => {
                    this.auth.createSession(res);
                    this.session = this.auth.getSession();
                    this.config.setLoading(false);
                });
        }
    }
    defaultLangChecker() {
        this.translate.addLangs(["en", "id"]);

        if (localStorage.getItem("locale")) {
            const browserLang = localStorage.getItem("locale");
            this.translate.use(browserLang.match(/en|id/) ? browserLang : "en");
        } else {
            localStorage.setItem("locale", "en");
            this.translate.setDefaultLang("en");
        }
    }
    changeLang(language: string) {
        localStorage.setItem("locale", language);
        this.translate.use(language);
    }

    onChangeWeb(web) {
        this.route.navigate(["/" + web]);
    }
}
