<flash-message-error></flash-message-error>
<flash-message-success></flash-message-success>
<div class="hide-mobile">
    <div class="flex">
        <div class="left">
            <banner-section></banner-section>
        </div>
        <div class="right flex justify-content-center">
            <div class="form-desktop text-center">
                <div class="mb-3 title"><b>Check Your Email</b></div>
                <img
                    class="mb-3"
                    src="assets/images/mailbox.svg"
                    style="max-width: 400px"
                />
                <div class="mb-3">
                    <div>We have sent you a password recovery link to</div>
                    <div>
                        <b>{{ email }}</b>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="p-fluid">
                        <button
                            pButton
                            pRipple
                            label="Open Email"
                            class="btn"
                        ></button>
                    </div>
                </div>
                <div class="text-center">
                    Didn’t receive email?
                    <a href="javascript:void(0);" (click)="resend()"
                        >Resend Email</a
                    >
                </div>
            </div>
        </div>
    </div>
</div>

<div class="hide-desktop">
    <banner-section-responsive></banner-section-responsive>
    <div class="flex justify-content-center position-up relative">
        <div class="container-mobile form-mobile">
            <div class="justify-content-center">
                <div class="mb-3 title"><b>Check Your Email</b></div>
                <img
                    class="mb-3"
                    src="assets/images/mailbox.svg"
                    style="max-width: 400px"
                />
                <div class="mb-3">
                    <div>We have sent you a password recovery link to</div>
                    <div>
                        <b>{{ email }}</b>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="p-fluid">
                        <button
                            pButton
                            pRipple
                            label="Open Email"
                            class="btn"
                        ></button>
                    </div>
                </div>
                <div class="text-center">
                    Didn’t receive email?
                    <a href="javascript:void(0);" (click)="resend()"
                        >Resend Email</a
                    >
                </div>
            </div>
        </div>
    </div>
</div>
