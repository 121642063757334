import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthForgotPasswordServiceService } from "./auth-forgot-password.service.service";
import { MessageBoxService } from "@core/service/message-box.service";
import { lastValueFrom } from "rxjs";
import { Router } from "@angular/router";

@Component({
    selector: "app-auth-forgot-password",
    templateUrl: "./auth-forgot-password.component.html",
    styleUrls: ["./auth-forgot-password.component.scss"],
})
export class AuthForgotPasswordComponent {
    form!: FormGroup;

    constructor(
        private srv: AuthForgotPasswordServiceService,
        private msg: MessageBoxService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.form = new FormGroup({
            email: new FormControl("", [Validators.required]),
        });
    }

    get f() {
        return this.form.controls;
    }

    submit() {
        lastValueFrom(this.srv.storeForgotPassword(this.form.value))
            .then((res) => {
                let resp = JSON.parse(res);
                this.msg.showSuccess(resp.data);
            })
            .then(() => {
                window.location.href = `/forgot-password-sent?email=${this.form.value.email}`;
            })
            .catch((err) => {
                let resp = JSON.parse(err);
                this.msg.showError(resp.errors.reason);
            });
    }
}
