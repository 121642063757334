<div class="text-center">
    <div>
        Oops!
    </div>
    <h1>
        404
    </h1>
    <div>
        Requested page not found!
    </div>
    
    <button (click)="onReturnHome()" pButton pRipple label="Take Me Home" class="mr-2 mb-2"></button>
</div>
