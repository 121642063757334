import { Injectable } from "@angular/core";
import { ApiService } from "@core/service/api.service";
import { HelperService } from "@core/service/helper-service";

@Injectable({
    providedIn: "root",
})
export class AuthRegisterServiceService {
    constructor(private api: ApiService) {}

    storeSignup(body) {
        return this.api.postLogin("auth/signup", body);
    }
}
