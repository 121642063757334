<div class="bg">
    <div
        class="container-mobile flex justify-content-between align-items-center"
    >
        <img src="assets/logo/krm-logo.svg" />
        <img src="assets/logo/LinovHR.svg" width="85px" height="16.86px" />
    </div>
    <div class="text-center mt-6">
        <img src="assets/images/auth-banner.png" width="350px" />
    </div>
</div>
