<div class="layout-topbar">
    <a class="p-link layout-topbar-button" href="#" (click)="appMain.toggleMenu($event)">
        <i class="fas fa-bars i-top"></i>
    </a>

    <a class="layout-topbar-logo" routerLink="">
        <img class="logo" src="assets/logo/LinovHR-logo.svg" alt="logo" />
    </a>
    <div class="layout-topbar-search">
        <span class="p-input-icon-right">
            <i class="fas fa-search"></i>
            <input type="text" pInputText placeholder="Search Menu" />
        </span>
    </div>
    <div></div>
    <a class="p-link layout-topbar-menu-button layout-topbar-button" href="#" (click)="appMain.toggleTopMenu($event)">
        <i class="fas fa-ellipsis-v i-top"></i>
    </a>

    <div class="layout-topbar-icon">
        <!-- <p-multiSelect [options]="cities" placeholder="PT. Kramayudha Ratu Motor" optionLabel="name"
            styleClass="p-multiSelect-topbar mr-2" dropdownIcon="fas fa-caret-down"></p-multiSelect> -->
        <!-- <app-lov uri="lovs/companies?isActive=true" label="Select Company" ></app-lov> -->

        <!-- <p-dropdown [options]="language" [(ngModel)]="selectedLang" s (onChange)="changeLang(selectedLang)"
            styleClass="p-dropdown-topbar" dropdownIcon="fas fa-caret-down"></p-dropdown> -->
        <i class="layout-topbar-button fas fa-bell i-top" style="font-size: 20px"></i>
        <a class="p-link layout-topbar-button" (click)="appMain.toggleTopMenu($event)">
            <i class="fas fa-user i-top"></i>
        </a>
    </div>

    <div class="layout-topbar-menu" [ngClass]="{
            'layout-topbar-menu-mobile-active': appMain.topMenuActive}">
        <!-- <span class="p-link layout-topbar-button-user">
            <i class="fas fa-user"></i>
            <span>
                {{ session?.user?.name ? session?.user?.name : "Username" }}
            </span>
        </span>
        //todo pasang switch untuk routting admin/ess/mss
        <div class="layout-topbar-button-lov" *ngIf="session?.user?.isSa">
            <app-lov id="changeComp" [customOption]="session?.user?.tenant?.companies"
                [value]="session?.selectedCompanyId" (selected)="selectCompany($event)"></app-lov>
        </div>
        <a (click)="doLogout(); appMain.toggleTopMenu($event)" class="p-link layout-topbar-button-user">
            <i class="fas fa-sign-out-alt"></i>
            <span>Logout</span>
        </a> -->
        <div class="account-menu">
            <div class="flex align-items-center mb-1 ml-3">
                <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png" style="width: 4rem;height:4rem;border-radius: 50%;" />
                <div class="flex-column ml-4">
                    <span class="span-username">{{ session?.user?.name ?
                        session?.user?.name : "Username" }}</span>
                    <p class="mb-1">admin</p>
                    <button pButton pRipple label="View Profile" class="btn-view-profile" (click)="onChangeWeb('ess/profile/person/personal-information')"></button>
                </div>
            </div>
            <ul>
                <li style="border-top: 1px solid var(--surface-200);" (click)="onChangeWeb('ess')">
                    <i class="fas fa-repeat"></i>
                    <a >Switch to Web ESS</a>
                </li>
                <li (click)="onChangeWeb('mss')">
                    <i class="fas fa-repeat"></i>
                    <a >Switch to Web MSS</a>
                </li>
                <li (click)="onChangeWeb('admin')">
                    <i class="fas fa-repeat"></i>
                    <a >Switch to Web Admin</a>
                </li>
                <li>
                    <i class="fas fa-user-gear"></i>
                    <a href="#">Account Settings</a>
                </li>
                <li (click)="doLogout(); appMain.toggleTopMenu($event)" style="border-top: 1px solid var(--surface-200);border-bottom-left-radius: 6px;border-bottom-right-radius: 6px;">
                    <i class="fas fa-arrow-right-from-bracket" style="color:var(--primary-red);font-size:1.3rem;font-weight: 700;"></i>
                    <a href="#" style="color:var(--primary-red);font-weight: 500;">Log Out</a>
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- <div class="layout-menubar" id="layout-menubar">
    <p-menubar [model]="items"> </p-menubar>
</div> -->
