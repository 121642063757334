<!-- Desktop -->
<div class="hide-mobile" style="background-color: #0e8ec5">
    <div class="px-8 py-6">
        <div class="flex justify-content-between align-items-center mb-5">
            <a class="p-link" style="color: white">
                <div class="flex">
                    <div style="font-weight: 900; font-size: 24px">
                        JOB Portal
                    </div>
                    <div
                        style="
                            font-weight: 600;
                            font-size: 14px;
                            margin-left: 4px;
                        "
                    >
                        by LinovHR
                    </div>
                </div>
            </a>
            <!-- <a class="p-link" style="color: white">About</a>
            <a class="p-link" style="color: white">Terms & Condition</a>
            <a class="p-link" style="color: white">Privacy Policy</a> -->
            <!-- <div>
                <input
                    type="text"
                    pInputText
                    placeholder="Be the first to know about new openings in your inbox"
                />
                <button
                    pButton
                    pRipple
                    label="Notify Me"
                    style="margin-left: 14px; background-color: #085576"
                ></button>
            </div> -->
        </div>
        <hr style="border: 1px solid #0d80b1" />
        <div class="flex justify-content-between mt-5">
            <div style="color: white">Copyright © {{ anio }} PT LinovHR</div>
            <a (click)="backToTop()" class="p-link" style="color: white">
                <span class="mr-5">Back to Top</span>
                <i class="fa-solid fa-arrow-up"></i>
            </a>
        </div>
    </div>
</div>
<!-- Mobile -->
<div class="hide-desktop" style="background-color: #0e8ec5">
    <div style="padding-top: 36px; padding-bottom: 36px">
        <a class="p-link" style="color: white">
            <div class="flex justify-content-center mb-5">
                <div style="font-weight: 900; font-size: 24px">JOB Portal</div>
                <div
                    style="font-weight: 600; font-size: 14px; margin-left: 4px"
                >
                    by LinovHR
                </div>
            </div>
        </a>
        <div class="text-center" style="margin-bottom: 20px">
            <!-- <div style="margin-bottom: 10px">
                <a class="p-link" style="color: white">About</a>
            </div>
            <div style="margin-bottom: 10px">
                <a class="p-link" style="color: white">Terms & Condition</a>
            </div>
            <div>
                <a class="p-link" style="color: white">Privacy Policy</a>
            </div> -->
        </div>
        <!-- <div
            style="
                color: white;
                margin-left: 24px;
                margin-right: 24px;
                margin-bottom: 10px;
            "
        >
            <div style="margin-bottom: 10px">
                Didn't see the job posting you had in mind?
            </div>
            <div class="p-fluid" style="margin-bottom: 10px">
                <input
                    type="text"
                    pInputText
                    placeholder="Be the first to know about new openings in your inbox"
                />
            </div>
            <div class="p-fluid">
                <button
                    pButton
                    pRipple
                    label="Notify Me"
                    style="background-color: #085576"
                ></button>
            </div>
        </div> -->
    </div>
</div>
