import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageBoxService } from "@core/service/message-box.service";
import { lastValueFrom } from "rxjs";
import { AuthResetPasswordServiceService } from "./auth-reset-password.service.service";

@Component({
    selector: "app-auth-reset-password",
    templateUrl: "./auth-reset-password.component.html",
    styleUrls: ["./auth-reset-password.component.scss"],
})
export class AuthResetPasswordComponent {
    form!: FormGroup;
    password!: string;

    constructor(
        private msg: MessageBoxService,
        private route: ActivatedRoute,
        private router: Router,
        private srv: AuthResetPasswordServiceService
    ) {}

    token: string;
    min8Char: boolean = false;
    lettNNum: boolean = false;

    ngOnInit(): void {
        this.token = this.route.snapshot.queryParamMap.get("token");
        this.form = new FormGroup({
            password: new FormControl("", [Validators.required]),
            password_confirmation: new FormControl("", [Validators.required]),
        });
    }

    get f() {
        return this.form.controls;
    }

    onChange(event: any) {
        if (event.target.value.length >= 8) {
            this.min8Char = true;
        } else {
            this.min8Char = false;
        }

        if (this.containsOnlyLettersAndNumbers(event.target.value)) {
            this.lettNNum = true;
        } else {
            this.lettNNum = false;
        }
    }

    containsOnlyLettersAndNumbers(str: any) {
        const pattern = /^[a-zA-Z0-9]+$/;
        return pattern.test(str);
    }

    submit() {
        const form = {
            token: this.token,
            password: this.form.value.password,
        };

        if (
            this.form.value.password === this.form.value.password_confirmation
        ) {
            if (this.min8Char && this.lettNNum) {
                lastValueFrom(this.srv.resetPassword(form))
                    .then((res) => {
                        let resp = JSON.parse(res);
                        this.msg.showSuccess(resp.data, null, false);
                    })
                    .then(() => {
                        setTimeout(() => {
                            this.router.navigate(["/login"]);
                        }, 3000);
                    })
                    .catch((err) => {
                        let resp = JSON.parse(err);
                        this.msg.showError(resp.errors.reason);
                    });
            } else {
                this.msg.showError("password does not match specifications");
            }
        } else {
            this.msg.showError("password does not match");
        }
    }
}
